import React from 'react'
import { Container, Row, Col } from '../../../../components/ui/wrapper'
import Timeline from '../../../../components/ui/timeline-ru'
import ImageOne from '../../../../data/images/bg/machines/smt/aoi/aoi-inline.jpg'
import ImageTwo from '../../../../data/images/bg/machines/smt/aoi/aoi-offline.jpg'
import { SectionWrap } from './style'


const TimelineArea = (props) => {
    const content = [
        {
            year: "Автомат",
            image: ImageOne,
            title: "Системы инспекции монтажа AOI",
            desc: "Автоматическая оптическая инспекция монтажа применяется для определения дефектов монтажа электронных компонентов на поверхность и в отверстия печатных плат. Системы оптического контроля определяют дефекты пайки, геометрию паянных соединений и читают обозначения компонентов. В условиях мелкосерийного производства применяют настольные машины в условиях серийного производства конвейерные системы в составе линии и как отдельностоящее оборудование.",
            path: "/оборудование-для-монтажа/поверхностный-монтаж/автоматическая-оптическая-инспекция/системы-инспекции",
            lang: "Подробно..."
        },
        {
            year: "Полу-автомат",
            image: ImageTwo,
            title: "Системы оптической инспекции AOI",
            desc: "Полу-автоматические оптические системы инспекции монтажа применяются для определения основных дефектов установки и пайки электронных компонентов. Используются в условиях мелкосерийного и серийного многономенклатурного производства. Рекомендуется для изделий с высокими требованиями в эксплуатации. Так же часто применяется контрактными производителями для небольших заказов для инспекции монтажа компонентов (AOI)",
            path: "/оборудование-для-монтажа/поверхностный-монтаж/автоматическая-оптическая-инспекция/инспекция-плат",
            lang: "Подробно..."
        }
    ]
    const contentua = [
        {
            year: "Автомат",
            image: ImageOne,
            title: "Системи інспекції монтажу AOI",
            desc: "Автоматична оптична інспекція монтажу застосовується для визначення дефектів монтажу електронних компонентів на поверхню і в отвори друкованих плат. Системи оптичного контролю визначають дефекти пайки, геометрію паянних з'єднань і читають позначення компонентів. В умовах дрібносерійного виробництва застосовують настільні машини в умовах серійного виробництва конвеєрні системи в складі лінії і як окреме обладнання.",
            path: "/ua/обладнання-для-монтажу/поверхневий-монтаж/автоматична-оптична-інспекція/системи-оптичної-інспекції",
            lang: "Докладно..."
        },
        {
            year: "Напів-автомат",
            image: ImageTwo,
            title: "Системи оптичної інспекції AOI",
            desc: "Напів-автоматичні оптичні системи інспекції монтажу застосовуються для визначення основних дефектів встановлення та  паяння електронних компонентів. Використовуються в умовах дрібносерійного і серійного багато номенклатурного виробництва. Рекомендується для виробів з високими вимогами в експлуатації. Так само часто застосовується контрактними виробниками для невеликих замовлень для інспекції монтажу компонентів (AOI).",
            path: "/ua/обладнання-для-монтажу/поверхневий-монтаж/автоматична-оптична-інспекція/оптична-інспекція-плат",
            lang: "Докладно..."
        }
    ]
    if (props.lang === "ru") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Автоматическая оптическая инспекция – передовое решение для технологических процессов управления качеством и показатель высокого уровня организации производства. Системы применяются  для контроля установки и монтажа электронных компонентов (AOI). По принципу работы оборудование AOI делится на два основных типа: сканирующие на базе сканера и видео на базе видеокамер. Системы могут иметь настольное исполнение для мелкосерийного производства и конвейерную конструкцию для работы в линии. Конвейерные машины совместно с устройствами загрузки / выгрузки плат могут составлять линиию AOI для автономного обслуживания несколько производственных линий. Автоматическая оптическая инспекция позволяет полность исключить человеческий фактор и  существенно снижает уровень брака на ранних стадия производства изделия.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={content} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    } else if (props.lang === "uk") {
        return (
            <SectionWrap>
                <Container>
                    <Row>
                    <Col lg={12}>
                           <h6 style={{textAlign: "justify"}}>Автоматична оптична інспекція - передове рішення для технологічних процесів управління якістю та показник високого рівня організації виробництва. Системи застосовуються для контролю встановлення і монтажу електронних компонентів (AOI). За принципом роботи обладнання AOI ділиться на два основних типи: сканувальні на базі сканера  і відео на базі відеокамер. Системи можуть мати настільне виконання для дрібносерійного виробництва і конвеєрну конструкцію для роботи в лінії. Конвеєрні машини спільно з пристроями завантаження / розвантаження плат можуть становити Ліни AOI для автономного обслуговування кількох виробничих ліній. Автоматична оптична інспекція дозволяє повністю виключити людський фактор і істотно знижує рівень дефектів на ранніх стадія виробництва.</h6>
                        </Col>
                        <Col lg={12}>
                            <Timeline items={contentua} />
                        </Col>
                    </Row>
                </Container>
            </SectionWrap>
        )
    }
}

export default TimelineArea
